import React from 'react'
import { Grid } from '@mui/material'
import Input from '../components/general/Input'
import Dropdown from '../components/general/Dropdown'
import SimpleForm from '../components/forms/SimpleForm'
import Section from '../components/general/Section'
import Title from '../components/general/Title'
import ButtonFilled from '../components/general/ButtonFilled'

const data = [
  {
    optionName: 'Introduction and Verification',
    scripts: [
      'Agent: Thank you for calling [Your Insurance Company Name]. My name is [Your Name], and I am here to assist you. May I have your name and policy number, please?',
      'Agent: Thank you, [Customer\'s Name], for verifying your details. How can I assist you with your insurance policy today?'
    ]
  },
  {
    optionName: 'Policy Inquiry',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! How can I assist you with your policy today? Are you looking to inquire about your coverage, update your information, or perhaps discuss any recent changes in your circumstances?',
      'Agent: I see. Let me assist you with that. To better help you, could you please provide me with some details about the specific issue you\'re facing?'
    ]
  },
  {
    optionName: 'Claim Assistance',
    scripts: [
      'Agent: Good day, [Customer\'s Name]! I understand you\'re calling about a claim. I\'m here to help. Can you please provide me with your claim number and a brief overview of the incident?',
      'Agent: Thank you for the information. I\'m sorry to hear about your situation. I\'ll review your claim right away and guide you through the next steps in the process.'
    ]
  },
  {
    optionName: 'Renewal and Policy Upgrade',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! I hope you\'re having a great day. It looks like your policy is up for renewal soon. Have you considered any changes or upgrades to your current coverage?',
      'Agent: Great! Let me go over the options available to you and ensure you have the best coverage tailored to your needs. Please bear with me for a moment while I gather the necessary information.'
    ]
  },
  {
    optionName: 'Customer Feedback and Closing',
    scripts: [
      'Agent: Thank you for choosing [Your Insurance Company Name]. Before we conclude, I\'d like to ask for your feedback. On a scale of 1 to 10, how satisfied are you with the service you received today?',
      'Agent: Thank you for your feedback, [Customer\'s Name]. We appreciate your business. If you have any more questions in the future, don\'t hesitate to reach out. Have a wonderful day!'
    ]
  },
  {
    optionName: 'Addressing Policy Changes',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! I understand you\'re looking to make changes to your policy. I\'m here to assist you with that. Could you please provide me with the details of the changes you\'d like to make?',
      'Agent: Thank you for letting me know. Let me update your policy information right away. I\'ll also explain how these changes might affect your coverage and premium. Please hold for a moment while I process this for you.'
    ]
  },
  {
    optionName: 'Assistance with Premium Payments',
    scripts: [
      'Agent: Good afternoon, [Customer\'s Name]! It appears your premium payment is due soon. I\'m here to assist you with the payment process. Would you prefer to make the payment over the phone, or shall I guide you through the online payment portal?',
      'Agent: Perfect! If you\'re making the payment online, please visit our website at [Your Insurance Company\'s Website]. If you encounter any issues, I\'ll be happy to guide you step by step. For phone payments, I\'m here to assist you. Let me know how you\'d like to proceed.'
    ]
  },
  {
    optionName: 'Handling Policy Cancellations',
    scripts: [
      'Agent: Hello, [Customer\'s Name]. I understand you\'re considering canceling your policy. I\'m here to assist you and discuss your options. May I know the reason for your decision? Perhaps there\'s something specific we can address or help you with.',
      'Agent: I appreciate you sharing that with me. I understand your concerns, and I\'m here to assist. Before you make a final decision, let me explain any alternatives or adjustments that might better suit your needs. Our goal is to ensure you have the coverage that\'s right for you.'
    ]
  },
  {
    optionName: 'Reviewing Policy Benefits',
    scripts: [
      'Agent: Good morning, [Customer\'s Name]! I hope you\'re doing well. I wanted to touch base and review your policy benefits with you. Are you aware of all the features and coverage options your policy offers? I\'d be happy to provide a detailed overview.',
      'Agent: Fantastic! Let me take a moment to go over your policy benefits with you. I\'ll explain any terms that might be unclear and ensure you fully understand the extent of your coverage. If you have any questions along the way, feel free to ask.'
    ]
  },
  {
    optionName: 'Follow-Up Call for Customer Satisfaction',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! I hope you\'re enjoying your coverage with us. I\'m calling to follow up and ensure you\'re completely satisfied with our services. Is there anything specific you\'d like to discuss or any concerns you have regarding your policy?',
      'Agent: Thank you for sharing your thoughts. I appreciate your feedback, and I\'ll make sure your concerns are addressed promptly. If there\'s anything else on your mind in the future, please don\'t hesitate to reach out. We value your relationship with [Your Insurance Company Name].'
    ]
  },
  {
    optionName: 'Comprehensive Policy Review',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! Thank you for choosing [Your Insurance Company Name] for your insurance needs. I hope you\'re having a great day. Today, I\'d like to offer you a comprehensive policy review to ensure that you have the coverage that best suits your current situation. To begin, could you please confirm your current address and contact information?',
      'Agent: Wonderful, thank you for confirming. Now, let\'s delve into your policy details. I see that you have our [Policy Name/Number] which provides coverage for [briefly explain coverage]. Since you\'ve been with us for [duration], I\'d like to ensure that your policy aligns with your current needs. \nFirstly, let\'s discuss your coverage limits. Based on your lifestyle changes and any significant purchases you\'ve made recently, it\'s crucial to evaluate if your coverage limits are adequate. Additionally, have there been any changes in your family, such as new additions or other dependents that we should be aware of?'
    ]
  },
  {
    optionName: 'Claims Assistance and Support',
    scripts: [
      'Agent: Good afternoon, [Customer\'s Name]! Thank you for reaching out to [Your Insurance Company Name]. I understand you\'re calling about a recent incident and need assistance with your claim. I\'m here to help. To start, could you please provide me with your claim number and a detailed description of the incident?',
      'Agent: Thank you for the information, [Customer\'s Name]. I\'m sorry to hear about your situation, and I appreciate you taking the time to report the incident. Rest assured, we are here to support you throughout the claims process. Firstly, let me assure you that your claim is being processed, and our claims team is diligently working on it. I understand that filing a claim can be stressful, so I want to keep you updated on the progress. You can expect regular updates via email or phone, whichever you prefer. Additionally, if you have any questions or concerns at any point, please don\'t hesitate to reach out to our dedicated claims hotline, available 24/7. Furthermore, I\'d like to guide you through the documentation required for the claim processing. I\'ll list down the necessary documents and information you might need to provide, so you can prepare them in advance. This will streamline the process and help us settle your claim as quickly as possible.'
    ]
  },
  {
    optionName: 'Policy Renewal and Loyalty Benefits',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! I hope you\'re doing well. I noticed that your policy is up for renewal soon, and I wanted to take a moment to thank you for your continued trust in [Your Insurance Company Name]. As a token of our appreciation for your loyalty, we have some exclusive benefits available for our long-standing customers. To begin, I\'d like to review your current policy to ensure it still meets your needs. Have there been any significant changes in your life recently, such as a new vehicle, address, or any additional drivers in your household?',
      'Agent: Thank you for letting me know. Based on the changes you\'ve mentioned, I recommend a policy update to ensure you have optimal coverage. Additionally, as part of our loyalty program, you qualify for a [mention specific discount or benefit], which will significantly reduce your premium. Let me calculate the updated premium with this loyalty discount applied. Please hold for a moment.'
    ]
  },
  {
    optionName: 'Explaining Policy Benefits in Detail',
    scripts: [
      'Agent: Good morning, [Customer\'s Name]! Thank you for calling [Your Insurance Company Name]. I understand you have questions about your policy benefits, and I\'m here to provide you with detailed information. To begin, could you please specify which aspect of your policy you\'d like to learn more about? Is it the coverage limits, benefits, or any specific terms and conditions?',
      'Agent: Thank you for letting me know. Let\'s discuss the coverage limits first. Your policy provides coverage for [list down the key coverage areas, e.g., property damage, bodily injury, medical payments, etc.]. For instance, under property damage, you\'re covered for damages to third-party properties, such as vehicles or structures, up to [specific dollar amount]. Now, moving on to benefits, your policy includes [mention additional benefits, e.g., roadside assistance, rental car coverage, etc.]. For example, our roadside assistance service ensures that you receive help in case of a breakdown, including services like towing, tire changes, and fuel delivery. Lastly, regarding terms and conditions, I\'d be happy to explain any specific clauses or conditions you\'re curious about. Please feel free to ask any questions you might have, and I\'ll provide detailed explanations.'
    ]
  },
  {
    optionName: 'Policy Comparison for New Customers',
    scripts: [
      'Agent: Hello, and thank you for considering [Your Insurance Company Name] for your insurance needs, [Customer\'s Name]! I understand you\'re exploring insurance options, and I\'m here to assist you in finding the perfect policy. To begin, could you please provide me with some information about your requirements and preferences?',
      'Agent: Great, thank you for sharing that. Based on your requirements, I have two policy options that might be a good fit for you: our [Policy A] and [Policy B]. Let me compare these policies for you in terms of coverage, premium, and additional benefits.'
    ]
  },
  {
    optionName: 'Addressing Billing and Payment Concerns',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! Thank you for contacting [Your Insurance Company Name]. I understand you have questions regarding your billing statement or payment options. I\'m here to assist you. Could you please specify your concern regarding the billing statement or payment method you\'d like to discuss?',
      'Agent: I see, thank you for letting me know. If you\'re facing issues with your billing statement, I can walk you through the charges and help clarify any discrepancies. If it\'s a payment issue, we have several convenient payment methods available, including online payments, automatic deductions, and mailed checks. Let\'s start by reviewing your billing statement together. Please provide me with your account number or policy number, and I\'ll pull up your statement for us to go through.'
    ]
  },
  {
    optionName: 'Policy Cancellation and Retention Efforts',
    scripts: [
      'Agent: Good afternoon, [Customer\'s Name]! I understand you\'re considering canceling your policy. I\'m here to assist you and address any concerns you might have. May I ask what has prompted your decision to cancel your policy?',
      'Agent: I appreciate you sharing that with me, [Customer\'s Name]. I understand your concerns, and I\'m here to help. Before you make a final decision, let me explain some options that might better suit your needs. One option is to review your policy and see if there are adjustments we can make to better match your requirements. For instance, if the premium is a concern, we can explore different deductible options or discounts you might be eligible for. Alternatively, if coverage is the issue, we can discuss policy modifications to align with your needs more accurately. I genuinely want to ensure you have the coverage you need, so I\'m here to explore every possibility with you. Would you be open to discussing potential modifications to your policy before making a decision?'
    ]
  },
  {
    optionName: 'Post-Claim Follow-Up and Customer Satisfaction',
    scripts: [
      'Agent: Hello, [Customer\'s Name]! I hope you\'re doing well. I\'m calling from [Your Insurance Company Name] to follow up on the recent claim you filed. I wanted to ensure that everything has been resolved to your satisfaction and to gather your feedback on our claims process. How did everything go with your claim experience?',
      'Agent: I\'m glad to hear that you had a positive experience, [Customer\'s Name]. Your satisfaction is our top priority, and I\'m delighted to know that we were able to assist you effectively. We appreciate your feedback, and it\'s valuable for us to continually improve our services. Is there anything specific about the claim process that stood out to you, either positively or negatively? Your insights will help us enhance our services and better meet the needs of our customers.'
    ]
  }
]

const initialFields = {
  scriptCategory: {
    type: Dropdown,
    category: 'scriptCategory',
    label: 'Script Category',
    options: data.map((item) => item.optionName),
    value: ''
  }
}

export default function AuthenticationGuide () {
  const [fields, setFields] = React.useState(initialFields)
  const [scriptIndex, setScriptIndex] = React.useState(0)

  const handleFieldChange = (event, entireField) => {
    event.preventDefault()
    const selectedValue = event.target.value

    const updatedField = {
      ...entireField,
      value: selectedValue
    }
    setFields({
      ...fields,
      [entireField.category]: updatedField
    })
    setScriptIndex(0)
  }

  const getScriptsByOptionName = (optionName) => {
    const dataItem = data.find((item) => item.optionName === optionName)
    return dataItem ? dataItem.scripts : null
  }

  const scripts = getScriptsByOptionName(fields.scriptCategory.value) ?? []

  const getScriptLabel = () => {
    if (scripts.length) {
      return `Script ${scriptIndex + 1}/${scripts.length}`
    } else {
      return 'Script'
    }
  }

  return (
    <>
      <Title title={'Call Scripts'} />
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleForm
                  fields={fields}
                  handleChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  rows={10}
                  maxRows={10}
                  label={getScriptLabel()}
                  selectedValue={scripts[scriptIndex] ?? ' '}
                  readOnly
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ButtonFilled
                      label={'Previous Script'}
                      onClick={() => setScriptIndex(Math.max(0, scriptIndex - 1))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonFilled
                      label={'Next Script'}
                      onClick={() => setScriptIndex(Math.min(scripts.length - 1, scriptIndex + 1))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </>
  )
}
