import React from 'react'
import NoteGenerator from '../pages/NoteGenerator'
import CallNavigation from '../pages/CallNavigation'
import AuthenticationGuide from '../pages/AuthenticationGuide'
import CallScripts from '../pages/CallScripts'
import QueueTransfers from '../pages/QueueTransfers'
import ServiceLevelAgreement from '../pages/ServiceLevelAgreement'
import Forms from '../pages/Forms'
import ConsumerPortal from '../pages/ConsumerPortal'
import Loan from '../pages/Loan'
import Illustration from '../pages/Illustration'
import ReinGuide from '../pages/ReinGuide'
import SurrenderCalendar from '../pages/SurrenderCalendar'
import Reminders from '../pages/Reminders'
import Settings from '../pages/Settings'

export function getPageByRoute (route) {
  const page = Object.values(Pages).find(page => page.routes.includes(route))
  return page
}

export const Pages = {
  NoteGenerator: {
    routes: ['/', '/note-generator'],
    element: <NoteGenerator />,
    label: 'Note Generator'
  },
  CallNavigation: {
    routes: ['/call-navigation'],
    element: <CallNavigation />,
    label: 'Call Navigation'
  },
  AuthenticationGuide: {
    routes: ['/authentication-guide'],
    element: <AuthenticationGuide />,
    label: 'Authentication Guide'
  },
  CallScripts: {
    routes: ['/call-scripts'],
    element: <CallScripts />,
    label: 'Call Scripts'
  },
  QueueTransfers: {
    routes: ['/queue-transfers'],
    element: <QueueTransfers />,
    label: 'Queue Transfers'
  },
  ServiceLevelAgreement: {
    routes: ['/service-level-agreement'],
    element: <ServiceLevelAgreement />,
    label: 'SLA'
  },
  Forms: {
    routes: ['/forms'],
    element: <Forms />,
    label: 'Forms'
  },
  ConsumerPortal: {
    routes: ['/consumer-portal'],
    element: <ConsumerPortal />,
    label: 'Consumer Portal'
  },
  Loan: {
    routes: ['/loan'],
    element: <Loan />,
    label: 'Loan'
  },
  Illustration: {
    routes: ['/illustration'],
    element: <Illustration />,
    label: 'Illustration'
  },
  ReinGuide: {
    routes: ['/rein-guide'],
    element: <ReinGuide />,
    label: 'REIN Guide'
  },
  SurrenderCalendar: {
    routes: ['/surrender-calendar'],
    element: <SurrenderCalendar />,
    label: 'Surrender Calendar'
  },
  Reminders: {
    routes: ['/reminders'],
    element: <Reminders />,
    label: 'Reminders'
  },
  Settings: {
    routes: ['/settings'],
    element: <Settings />,
    label: 'Settings'
  }
}

export const Sections = {
  CallReady: [
    Pages.CallNavigation,
    Pages.AuthenticationGuide,
    Pages.CallScripts,
    Pages.QueueTransfers
  ],
  AgentAssist: [
    Pages.ServiceLevelAgreement,
    Pages.Forms,
    Pages.ConsumerPortal,
    Pages.Loan,
    Pages.Illustration,
    Pages.ReinGuide,
    Pages.SurrenderCalendar
  ]
}
