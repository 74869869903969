import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ListItem, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
  width: 100%;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  padding-left: 8px;
`

const StyledLabel = styled(Typography)`
  font-size: 12px;
  padding-left: 10px;
`

const StyledListItem = styled(ListItem)`
  width: 100%;
  box-sizing: border-box;
`

export function NavigationParentItem ({ label, route, icon, section }) {
  const location = useLocation()
  let selected = !section && route && location.pathname === route
  if (section) {
    selected = section.some(page => page.routes.includes(location.pathname))
  }

  return (
    <StyledLink to={route ?? '#'}>
      <StyledListItem>
        <Grid container rowSpacing={0} columnSpacing={0} sx={{
          backgroundColor: selected ? 'primary.main' : 'secondary.background',
          color: selected ? 'primary.invert' : 'primary.main',
          borderRadius: '4px',
          paddingTop: '8px',
          paddingBottom: '8px'
        }}>
          <StyledGrid item xs={2}>
            {icon}
          </StyledGrid>
          <StyledGrid item xs={10}>
            <StyledLabel>{label}</StyledLabel>
          </StyledGrid>
        </Grid>
      </StyledListItem>
    </StyledLink>
  )
}

export function NavigationChildItem ({ label, route, icon }) {
  const location = useLocation()
  const selected = route && location.pathname === route

  return (
    <StyledLink to={route ?? '#'}>
      <StyledListItem>
        <Grid container rowSpacing={0} columnSpacing={0} sx={{
          backgroundColor: selected ? 'primary.background' : 'secondary.background',
          borderRadius: '4px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }}>
          <StyledGrid item xs={2}>
            {icon}
          </StyledGrid>
          <StyledGrid item xs={10}>
            <StyledLabel>{label}</StyledLabel>
          </StyledGrid>
        </Grid>
      </StyledListItem>
    </StyledLink>
  )
}
