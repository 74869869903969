import React from 'react'
import { Grid } from '@mui/material'
import Section from '../components/general/Section'
import Title from '../components/general/Title'

export default function QueueTransfers () {
  return (
    <>
      <Title title={'Queue Transfers'} />
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Section>
            Coming Soon
          </Section>
        </Grid>
      </Grid>
    </>
  )
}
