import React from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2em',
  fontWeight: 'bold',
  marginBottom: '0.3em',
  marginTop: '0.3em'
}))

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.7em',
  fontWeight: 'bold',
  marginBottom: '1.7em'
}))

export default function Title ({ subtitle, title }) {
  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <StyledSubtitle>
        {subtitle ?? ''}
      </StyledSubtitle>
      <StyledTitle>
        {title ?? ''}
      </StyledTitle>
    </Grid>
  )
}
