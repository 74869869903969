import React from 'react'
import Button from '@mui/material/Button'

export default function ButtonFilled ({
  label,
  onClick,
  disabled = false
}) {
  return (
    <Button
      sx={{ marginBottom: '1em' }}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      disableElevation
      fullWidth
    >
      {label}
    </Button>
  )
}
