import { createTheme } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

export default createTheme({
  palette: {
    primary: {
      main: '#023047',
      invert: '#FFFFFF',
      background: '#F0F3F4'
    },
    secondary: {
      main: '#000000',
      invert: '#FFFFFF',
      background: '#FAFAFA'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: 0.15
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#0000003B',
          '--TextField-brandBorderHoverColor': '#023047',
          '--TextField-brandBorderFocusedColor': '#0000003B',
          '--TextField-brandFontColor': '#023047',
          '--TextField-placeholderFontColor': '#023047',
          '& label': {
            color: 'var(--TextField-placeholderFontColor)',
            fontWeight: 300
          },
          '& label.MuiInputLabel-shrink': {
            color: 'var(--TextField-brandFontColor)',
            fontWeight: 500
          }
        }
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& label': {
              fontSize: '23px'
            }
          }
        },
        {
          props: { size: 'medium' },
          style: {
            '& label': {
              fontSize: '18px'
            }
          }
        },
        {
          props: { size: 'small' },
          style: {
            '& label': {
              fontSize: '16px'
            }
          }
        }
      ]
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          fontSize: 16
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)'
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)'
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)'
          }
        }
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& fieldset legend span': {
              fontSize: '18px'
            }
          }
        },
        {
          props: { size: 'medium' },
          style: {
            '& fieldset legend span': {
              fontSize: '14px'
            }
          }
        },
        {
          props: { size: 'small' },
          style: {
            '& fieldset legend span': {
              fontSize: '12px'
            }
          }
        }
      ]
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before, &:after': {
            borderBottom: '1px solid var(--TextField-brandBorderColor)'
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '1px solid var(--TextField-brandBorderHoverColor)'
          },
          '&.Mui-focused:after': {
            borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '1px solid var(--TextField-brandBorderColor)'
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '1px solid var(--TextField-brandBorderHoverColor)'
          },
          '&.Mui-focused:after': {
            borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#0000003B',
          '--TextField-brandFontColor': '#023047',
          '--TextField-placeholderFontColor': '#023047',
          '& label.dropdown-label': {
            color: 'var(--TextField-brandFontColor)',
            fontWeight: 300,
            fontSize: 16
          },
          '& label.dropdown-label.MuiInputLabel-shrink': {
            color: 'var(--TextField-brandFontColor)',
            fontWeight: 500
          },
          '& div.custom-dropdown': {
            borderColor: 'var(--TextField-brandBorderColor)',
            fontWeight: 300
          }
        }
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& label.dropdown-label.MuiInputLabel-shrink': {
              fontSize: '23px'
            }
          }
        },
        {
          props: { size: 'medium' },
          style: {
            '& label.dropdown-label.MuiInputLabel-shrink': {
              fontSize: '18px'
            }
          }
        },
        {
          props: { size: 'small' },
          style: {
            '& label.dropdown-label.MuiInputLabel-shrink': {
              fontSize: '16px'
            }
          }
        }
      ]
    },
    MuiList: {
      styleOverrides: {
        root: {
          '--TextField-brandFontColor': '#023047',
          '--TextField-brandBorderColor': '#0000003B',
          padding: 0,
          '& li.dropdown-option': {
            color: 'white',
            fontWeight: 300,
            fontSize: 16,
            background: 'var(--TextField-brandFontColor)',
            borderBottom: '1px solid var(--TextField-brandBorderColor)',
            padding: '15px'
          },
          '& li.dropdown-option:hover': {
            background: '#084360'
          },
          '& li.dropdown-option.Mui-selected': {
            background: '#084360'
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& th': {
            color: '#023047',
            fontWeight: 500
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.00)'
          },
          '&:nth-of-type(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: 300
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#0000003B',
          '--TextField-brandBorderHoverColor': '#023047',
          '--TextField-brandBorderFocusedColor': '#0000003B',
          '--TextField-brandFontColor': '#023047',
          '--TextField-placeholderFontColor': '#023047',
          bordeRradius: '4px',
          background: '#FFB703',
          padding: '8px 50px',
          color: 'var(--TextField-brandFontColor)',
          fontSize: 15,
          fontWeight: 600,
          lineHeight: '26px',
          letterSpacing: '0.46px',
          '&:hover': {
            backgroundColor: '#ffd166'
          }
        }
      }
    }
  }
})
