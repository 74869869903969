import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Navigation from './components/nagivation/Navigation'
import theme from './constants/Theme'
import { Pages } from './constants/Pages'
import './index.css'

function generateRoutesByPage (page) {
  return page.routes.map(route => {
    return {
      path: route,
      element: <Navigation>{page.element}</Navigation>
    }
  })
}

const router = createBrowserRouter(Object.values(Pages).map(generateRoutesByPage).flat())

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)
