import React from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)`
  margin-bottom: 1em;
`

function Input ({
  handleChange,
  selectedValue,
  label = 'Input Label',
  rows = 1,
  maxRows = 1,
  helperText = '',
  disabled = false,
  fullWidth = true,
  error = false,
  size = 'large'
}) {
  return (
    <StyledTextField
      key={`outlined-input-${label}`}
      value={selectedValue}
      label={label}
      onChange={handleChange}
      minRows={rows}
      maxRows={maxRows}
      fullWidth={fullWidth}
      disabled={disabled}
      helperText={helperText}
      error={error}
      size={size}
      multiline
    />
  )
}

export default Input
