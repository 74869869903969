import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const StyledBox = styled(Box)(({ theme }) => (
  {
    backgroundColor: theme.palette.primary.invert,
    padding: '1em',
    borderRadius: '10px'
  }
))

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '1.7em'
}))

export default function Section ({ label, title, subtitle, children }) {
  return (
    <Box>
      <StyledBox>
        {label &&
          <StyledSectionTitle>
            {label}
          </StyledSectionTitle>
        }
        {children}
      </StyledBox>
    </Box>
  )
}
