import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'

const StyledFormControl = styled(FormControl)`
  margin-bottom: 1em;
`

export default function Dropdown ({
  options = [],
  label = 'Dropdown Label',
  helperText = '',
  disabled = false,
  fullWidth = true,
  selectedValue = 'Select an Option',
  handleChange,
  size = 'large'
}) {
  return (
    <StyledFormControl
      className='dropdown-form-control'
      fullWidth={fullWidth}
      disabled={disabled}
      size={size}
    >
      <InputLabel className={'dropdown-label'}>
        {label}
      </InputLabel>

      <Select
        className={'custom-dropdown'}
        id={`select-${label}`}
        value={selectedValue}
        label={label}
        onChange={handleChange}
      >
        {options.map((option) =>
          <MenuItem
            key={'option-' + option}
            className={'dropdown-option'}
            value={option}
          >
            {option}
          </MenuItem>
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </StyledFormControl>
  )
}
