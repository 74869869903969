import React from 'react'
import Input from '../components/general/Input'
import Dropdown from '../components/general/Dropdown'
import SimpleForm from '../components/forms/SimpleForm'
import ButtonFilled from '../components/general/ButtonFilled'
import Section from '../components/general/Section'
import Title from '../components/general/Title'
import { Grid } from '@mui/material'

const initialDataCollectionFields = {
  policyNum: {
    type: Input,
    category: 'policyNum',
    label: 'Policy Number',
    rows: 1,
    value: ''
  },
  callerName: {
    type: Input,
    category: 'callerName',
    label: 'Caller Name',
    rows: 1,
    value: ''
  },
  system: {
    type: Dropdown,
    category: 'system',
    label: 'System',
    options: ['CRM', 'ERP', 'SCM'],
    value: ''
  },
  area: {
    type: Dropdown,
    category: 'area',
    label: 'Area',
    options: ['Support', 'Billing', 'Escalation'],
    value: ''
  },
  businessArea: {
    type: Input,
    category: 'businessArea',
    label: 'Business Area',
    rows: 1,
    value: ''
  },
  workType: {
    type: Dropdown,
    category: 'workType',
    label: 'Work Type',
    options: ['Management', 'Resolution', 'Assistance'],
    value: ''
  },
  status: {
    type: Dropdown,
    category: 'status',
    label: 'Status',
    options: ['Open', 'Closed', 'Pending'],
    value: ''
  }
}

const initialAdditionalFields = {
  relationship: {
    type: Dropdown,
    category: 'relationship',
    label: 'Relationship',
    options: ['Mother', 'Father', 'Sibling', 'Spouse', 'Child', 'Other'],
    value: ''
  },
  verifiedCaller: {
    type: Dropdown,
    category: 'verifiedCaller',
    label: 'Verified Caller',
    options: ['Yes', 'No'],
    value: ''
  },
  emailAddress: {
    type: Input,
    category: 'emailAddress',
    label: 'Email Address',
    value: ''
  },
  additionalPolicyNo: {
    type: Input,
    category: 'additionalPolicyNo',
    label: 'Additional Policy #',
    value: ''
  },
  notes: {
    type: Input,
    category: 'notes',
    label: 'Additional Notes',
    value: ''
  }
}

export default function NoteGenerator () {
  const [collectionFields, setCollectionFields] = React.useState(initialDataCollectionFields)
  const [additionalFields, setAdditionalFields] = React.useState(initialAdditionalFields)

  const handleCollectionFieldsChange = (event, entireField) => {
    event.preventDefault()
    const selectedValue = event.target.value

    const updatedField = {
      ...entireField,
      value: selectedValue
    }
    setCollectionFields({
      ...collectionFields,
      [entireField.category]: updatedField
    })
  }

  const handleAdditionalFieldsChange = (event, entireField) => {
    event.preventDefault()
    const selectedValue = event.target.value

    const updatedField = {
      ...entireField,
      value: selectedValue
    }
    setAdditionalFields({
      ...additionalFields,
      [entireField.category]: updatedField
    })
  }

  const generateNote = () => {
    const collectionFieldsValues = Object.values(collectionFields)
    const additionalFieldsValues = Object.values(additionalFields)

    const allFields = collectionFieldsValues.concat(additionalFieldsValues)

    let note = ''
    allFields.forEach((field) => {
      if (field.value) {
        note += `${field.label}: ${field.value ?? ''}\n`
      }
    })
    return note
  }

  const downloadNote = () => {
    const fileData = generateNote()
    const blob = new Blob([fileData], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `generated-note-${Date.now()}.txt`
    link.href = url
    link.click()
  }

  return (
    <>
      <Title title={'Note Generator'} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Section
            label={'1. Initial Data Collection'}
          >
            <SimpleForm
              fields={collectionFields}
              handleChange={handleCollectionFieldsChange}
            />
          </Section>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Section label={'2. Additional Fields'}>
            <SimpleForm
              fields={additionalFields}
              handleChange={handleAdditionalFieldsChange}
            />
          </Section>
        </Grid>
        <Grid item xs={12}>
          <Section label={'3. Auto-Generated Note'}>
            <Grid container spacing={6}>
              <Grid item xs={3}>
                <ButtonFilled
                  label={'Copy to Clipboard'}
                  onClick={() => { navigator.clipboard.writeText(generateNote()) }}
                />
                <ButtonFilled
                  label={'Download'}
                  onClick={downloadNote}
                />
              </Grid>
              <Grid item xs={9}>
                <Input
                  rows={4}
                  maxRows={4}
                  label={'Note Summary'}
                  selectedValue={generateNote()}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </>
  )
}
