import React from 'react'
import { Grid } from '@mui/material'
import Input from '../components/general/Input'
import Dropdown from '../components/general/Dropdown'
import SimpleForm from '../components/forms/SimpleForm'
import Section from '../components/general/Section'
import Title from '../components/general/Title'

const data = {
  agent: {
    optionName: 'Agent',
    authenticatedText: [
      'Agent Code',
      'SSN'
    ],
    notAuthenticatedText: [
      'Agent Code',
      'SSN',
      'Date of Birth',
      'Contact Number'
    ],
    additionalInfoText: [
      'Residence Address',
      'Insurance License Number'
    ]
  },
  agencyPrincipal: {
    optionName: 'Agency Principal',
    authenticatedText: [
      'Agency Code',
      'TIN (Last 4 digits)'
    ],
    notAuthenticatedText: [
      'Agency Code',
      'TIN (Last 4 digits)',
      'Business State',
      'License Number',
      'Lines of Business'
    ],
    additionalInfoText: [
      'Business Address',
      'Principal(s) of the Corp'
    ]
  },
  policyOwner: {
    optionName: 'Policy Owner',
    authenticatedText: [
      'Full Name',
      'Policy Number'
    ],
    notAuthenticatedText: [
      'Full Name',
      'Policy Number',
      'Product Type',
      'Writing Agent'
    ],
    additionalInfoText: [
      'Permanent Address',
      'Premium Amount'
    ]
  },
  beneficiaries: {
    optionName: 'Beneficiaries',
    authenticatedText: [
      'Full Name',
      'Policy Number'
    ],
    notAuthenticatedText: [
      'Full Name',
      'Policy Number',
      'Relationship to PO',
      'DOB'
    ],
    additionalInfoText: [
      'Residence Address',
      'Document as proof of relationship'
    ]
  },
  familyMemberOrSpouse: {
    optionName: 'Family Member or Spouse',
    authenticatedText: [
      'Full Name',
      'Policy Number'
    ],
    notAuthenticatedText: [
      'Full Name',
      'Policy Number',
      'Relationship to PO',
      'DOB'
    ],
    additionalInfoText: [
      'Residence Address',
      'Document as proof of relationship',
      'Agent Code (if related to writing agent)'
    ]
  },
  financialAdvisor: {
    optionName: 'Financial Advisor',
    authenticatedText: [
      'Full Name',
      'Policy Number'
    ],
    notAuthenticatedText: [
      'Full Name',
      'Policy Number',
      'State License',
      'Contact Details'
    ],
    additionalInfoText: [
      'FINRA Registration',
      'Permanent Address'
    ]
  }
}

const initialFields = {
  relationship: {
    type: Dropdown,
    category: 'relationship',
    label: 'Relationship',
    options: Object.values(data).map((item) => item.optionName),
    value: ''
  }
}

export default function AuthenticationGuide () {
  const [fields, setFields] = React.useState(initialFields)

  const handleFieldChange = (event, entireField) => {
    event.preventDefault()
    const selectedValue = event.target.value

    const updatedField = {
      ...entireField,
      value: selectedValue
    }
    setFields({
      ...fields,
      [entireField.category]: updatedField
    })
  }

  const getDataByOptionName = (optionName) => {
    const dataItem = Object.values(data).find((item) => item.optionName === optionName)
    return dataItem === -1 ? null : dataItem
  }

  const targetData = getDataByOptionName(fields.relationship.value) ?? {
    authenticatedText: [' '],
    notAuthenticatedText: [' '],
    additionalInfoText: [' ']
  }

  return (
    <>
      <Title title={'Authentication Guide'} />
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleForm
                  fields={fields}
                  handleChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rows={4}
                  maxRows={4}
                  label={'Authenticated'}
                  selectedValue={targetData.authenticatedText.join('\n')}
                  readOnly
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rows={4}
                  maxRows={4}
                  label={'Not Authenticated'}
                  selectedValue={targetData.notAuthenticatedText.join('\n')}
                  readOnly
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  rows={4}
                  maxRows={4}
                  label={'Additional Info'}
                  selectedValue={targetData.additionalInfoText.join('\n')}
                  readOnly
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </>
  )
}
