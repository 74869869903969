import React from 'react'
import Dropdown from '../general/Dropdown'
import Input from '../general/Input'

export default function SimpleForm ({
  fields,
  handleChange,
  size = 'small'
}) {
  return (
    <>
      {
        Object.keys(fields).map((formItemKey) => {
          const formItem = fields[formItemKey]
          if (formItem.type === Dropdown) {
            return (
              <Dropdown
                key = {`${formItem.type}-${formItem.category}`}
                options = {formItem.options}
                label = {formItem.label}
                selectedValue = {formItem.value}
                handleChange = {(event) => handleChange(event, formItem)}
                size = {size}
              />
            )
          } else if (formItem.type === Input) {
            return (
              <Input
                key = {`${formItem.type}-${formItem.category}`}
                rows = {formItem.rows}
                label = {formItem.label}
                selectedValue = {formItem.value}
                handleChange = {(event) => handleChange(event, formItem)}
                size = {size}
              />
            )
          } else {
            return (
              null
            )
          }
        })
      }
    </>
  )
}
