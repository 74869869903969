import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Drawer, Grid, List } from '@mui/material'
import { styled } from '@mui/material/styles'
import EditNoteIcon from '@mui/icons-material/EditNote'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
// import SupportAgentIcon from '@mui/icons-material/SupportAgent'
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
// import TuneIcon from '@mui/icons-material/Tune'
import { NavigationParentItem, NavigationChildItem } from './NavigationItem'
import { Pages, Sections } from '../../constants/Pages'
import logo from '../../assets/logo.svg'

const StyledNavBar = styled(Grid)(({ theme }) => (
  {
    height: '100vh',
    backgroundColor: theme.palette.secondary.background
  }
))

const Content = styled(Grid)(({ theme }) => (
  {
    backgroundColor: theme.palette.primary.background,
    padding: '0 3em 0 3em'
  }
))

const StyledDrawer = styled(Drawer)(({ theme }) => (
  {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .MuiDrawer-paper': {
      display: 'inline',
      position: 'relative',
      backgroundColor: theme.palette.secondary.background,
      boxSizing: 'border-box',
      width: 'auto'
    },
    '& .MuiPaper-root': {
      display: 'grid',
      gridAutoRows: 'min-content',
      position: 'relative',
      backgroundColor: theme.palette.secondary.background,
      overflowX: 'hidden',
      width: 'auto'
    }
  }
))

const StyledAccordion = styled(Accordion)`
  box-shadow: unset;
  border: none;
  margin: 0 !important;
  &:before {
    height: 0;
    margin: 0 !important;
  }
  &:after {
    margin: 0 !important;
  }
  & .MuiPaper-root {
    margin: 0 !important;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  margin: 0;
  border: unset;
  & {
    min-height: unset !important;
  }
  & .MuiAccordionSummary-content {
    border: unset;
    width: 100% !important;
    margin: 0 !important;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin: 0;
  border: unset;
`

const StyledLogo = styled('img')`
  width: 100%;
  height: 100px;
`

const StyledList = styled(List)`
  display: table;
  overflow: hidden;
  & {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 0px; // todo
  }
`

const drawerWidthXs = 6
const drawerWidthSm = 5
const drawerWidthMd = 3
const drawerWidthLg = 2

export default function Navigation ({ children }) {
  return (
    <Grid container>
      <StyledNavBar
        item
        minWidth={150}
        xs={drawerWidthXs}
        sm={drawerWidthSm}
        md={drawerWidthMd}
        lg={drawerWidthLg}
      >
        <StyledDrawer
          variant={'permanent'}
          open
        >
          <StyledLogo src={logo} />
          <StyledList>
            <NavigationParentItem
              label={Pages.NoteGenerator.label}
              route={Pages.NoteGenerator.routes[0]}
              icon={<EditNoteIcon />}
            />
            <StyledAccordion>
              <StyledAccordionSummary>
                <NavigationParentItem
                  label={'Call Ready'}
                  icon={<PhoneForwardedIcon />}
                  section={Sections.CallReady}
                />
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <List>
                  <NavigationChildItem
                    label={Pages.AuthenticationGuide.label}
                    route={Pages.AuthenticationGuide.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.CallScripts.label}
                    route={Pages.CallScripts.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.CallNavigation.label}
                    route={Pages.CallNavigation.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.QueueTransfers.label}
                    route={Pages.QueueTransfers.routes[0]}
                  />
                </List>
              </StyledAccordionDetails>
            </StyledAccordion>
            {/* <StyledAccordion>
              <StyledAccordionSummary>
                <NavigationParentItem
                  label={'Agent Assist'}
                  icon={<SupportAgentIcon />}
                  section={Sections.AgentAssist}
                />
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <List>
                  <NavigationChildItem
                    label={Pages.ServiceLevelAgreement.label}
                    route={Pages.ServiceLevelAgreement.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.Forms.label}
                    route={Pages.Forms.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.ConsumerPortal.label}
                    route={Pages.ConsumerPortal.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.Loan.label}
                    route={Pages.Loan.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.Illustration.label}
                    route={Pages.Illustration.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.ReinGuide.label}
                    route={Pages.ReinGuide.routes[0]}
                  />
                  <NavigationChildItem
                    label={Pages.SurrenderCalendar.label}
                    route={Pages.SurrenderCalendar.routes[0]}
                  />
                </List>
              </StyledAccordionDetails>
            </StyledAccordion>
            <NavigationParentItem
              label={Pages.Reminders.label}
              route={Pages.Reminders.routes[0]}
              icon={<AccessAlarmIcon />}
            />
            <NavigationParentItem
              label={Pages.Settings.label}
              route={Pages.Settings.routes[0]}
              icon={<TuneIcon />}
            /> */}
          </StyledList>
        </StyledDrawer>
      </StyledNavBar>
      <Content
        item
        xs={12 - drawerWidthXs}
        sm={12 - drawerWidthSm}
        md={12 - drawerWidthMd}
        lg={12 - drawerWidthLg}
      >
        {children}
      </Content>
    </Grid>
  )
}
